import React from "react";
import {Trans} from "@lingui/react";
import {paths} from "../routing";
import {NavigationType} from "~components/navigation/navigation-type";
import {buildMenu} from "./build-menu";

export const footerItemsCol1 = [
    {
        name: <Trans id="global.cta.register" />,
        link: paths.join_us,
    },
    {
        name: <Trans id="global.menu.link.pricing" />,
        link: paths.pricing,
    },
    {
        name: <Trans id="global.menu.link.faq" />,
        link: paths.faq,
    },
    {
        name: <Trans id="global.menu.link.terms_and_conditions" />,
        link: paths.terms,
    },
];

export const footerItemsCol2 = [
    {
        name: <Trans id="global.menu.link.about" />,
        link: paths.about,
    },
    {
        name: <Trans id="global.menu.link.blog" />,
        link: paths.blog,
    },
    {
        name: <Trans id="global.menu.link.contact" />,
        link: paths.contact,
    },
];

export const footerItemsCol3 = [
    {
        name: 'Facebook',
        link: paths.facebook,
        target: '_blank',
    },
    {
        name: 'Twitter',
        link: paths.twitter,
        target: '_blank',
    },
    {
        name: 'LinkedIn',
        link: paths.linkedin,
        target: '_blank',
    },
    {
        name: 'Instagram',
        link: paths.instagram,
        target: '_blank',
    },
];

export const globalFooters = {
    footer_col_1: buildMenu(
        NavigationType.BLOCK_FOOTER_MENU_1,
        <Trans id="global.menu.products" />,
        footerItemsCol1,
    ),
    footer_col_2: buildMenu(
        NavigationType.BLOCK_FOOTER_MENU_2,
        <Trans id="global.menu.company" />,
        footerItemsCol2,
    ),
    footer_col_3: buildMenu(
        NavigationType.BLOCK_FOOTER_MENU_3,
        <Trans id="global.menu.social_media" />,
        footerItemsCol3,
    ),
}
