import React, { useContext } from 'react';
import { paths } from '~/../shared/routing';
import styled from 'styled-components';
import { YellowButton } from '../../styles/buttons';
import { smallScreenBreakpoint1064px, tabletBreakpoint } from '~/styles/common';
import { RowWrapper } from "../../styles/welcome";
import { withI18n } from '@lingui/react';
import { t } from '@lingui/macro';
import TransHTML from '~/components/transHTML';
import { Trans } from '@lingui/react';
import { getPath } from '~/../shared/utils';
import { LangContext } from '~/components/../providers/LangProvider';

function PaymentLinkWelcome({ i18n }) {
    const ctxLang = useContext(LangContext);
    return (
        <Container id='welcome' className={'mt-lg-0'}>
            <Wrapper>
                <LeftSection>
                    <Heading>
                        <Trans id={t`global.payment_link.all.payment.details.title`} />
                    </Heading>
                    <Paragraph>
                        <TransHTML id={t`global.payment_link.all.payment.details.subTitle`} />
                    </Paragraph>
                    <CustomYellowButton href={getPath(paths.join_us, ctxLang.state.country)}>
                        <Trans id='global.signUp' />
                    </CustomYellowButton>
                </LeftSection>
                <RightSection src={i18n._(t`global.payment.link.welcome.img`)} />
            </Wrapper>
        </Container>
    );
}

export default withI18n()(PaymentLinkWelcome);

const Wrapper = styled(RowWrapper)`
    max-width: 1100px;
    margin: 0 auto;
    padding: 3rem 1rem;
    align-items: center;

    @media (min-width: ${tabletBreakpoint}) {
        padding: 4rem 1rem;
        justify-content: center;
        text-align: center;
        flex-direction: column;   
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        justify-content: space-between;
        align-items: start;
        text-align: left;
        flex-direction: unset;
    }
`;

const LeftSection = styled.div`
    max-width: 416px;
    text-align: center;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
       max-width: 563px;
       text-align: left;
    }
`;

// todo: this should be H1 !!!
const Heading = styled.div`
    font-family: ${({ theme }) => theme.primaryFont};
    font-weight: 700;
    line-height: 1.3;
    color: black;
    text-align: center;
    font-size: 2.2rem;
    margin-bottom: 2rem;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        text-align: left;
        font-size: 3rem;
    }
`;

const Paragraph = styled.p`
    font-family: ${({ theme }) => theme.primaryFont};
    color: black;
    font-style: normal;
    font-weight: 300;
    font-size: 1.1rem;
    max-width: 250px;
    margin: 0 auto 2rem auto ;
    text-align: center;
    line-height: 1.9;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        line-height: 1.6;
        max-width: 360px;
        margin: 0 0 2rem 0;
        font-size: 1.3rem;
        text-align: left;
        font-weight: 400;
    }
`;

const RightSection = styled.img`
    display: none;

    @media (min-width: ${tabletBreakpoint}) {
        display: flex;
        align-self: center;
        justify-self: center;
        margin-top: 2rem;
    }
`;

const CustomYellowButton = styled(YellowButton)`
    text-transform: none;
    font-family: ${({ theme }) => theme.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.5px;
`;

const Container = styled.div`
    width: 100%;
`;
