import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { t } from '@lingui/macro';
import { Section, CTAButton } from '~/styles/welcome';
import { paths } from '~/../shared/routing';
import { getPath } from '~/../shared/utils';
import TransHTML from '~/components/transHTML';
import { smallScreenBreakpoint1064px, tabletBreakpoint } from '~/styles/common';
import {Country} from "~services/i18n/country";
import {OperationSystems} from "~components/ocellot/features/os";

// todo: get rid of "className" probably related with bootstrap, sass
export function OcellotWelcome({ i18n }: { i18n: any }) {
    return (
        <PersonalWrapper id='welcome' className={'mt-lg-0'}>
            <PersonalContainer>
                {/* todo: wrong alt */}
                <LeftImage
                    alt=''
                    src={i18n._(t`cz.ocellot.index.image`)}
                />
                <Gradient>
                    {/* todo: wrong alt */}
                    <CenterImage
                        alt=''
                        src={i18n._(t`cz.ocellot.index.image`)}
                    />
                </Gradient>
                <RightSection>
                    <PersonalHeading>
                        <TransHTML id={t`cz.ocellot.index.title`} />
                    </PersonalHeading>
                    <Button href={getPath(paths.pos_sign_up, Country.CZ)}>
                        <Trans id="cz.ocellot.index.signUpButton" />
                    </Button>
                    <Compatibility>
                        <OperationSystems />
                    </Compatibility>
                </RightSection>
            </PersonalContainer>
        </PersonalWrapper>
    );
}

const Container = styled.div`
    gap: 2rem;
    margin: 2rem 0;
    display: none;

    @media (min-width: ${tabletBreakpoint}) {
        display: flex;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        justify-content: space-between;
        transform: translateY(30px);
        margin: 0;
        width: 100%;
    }
`;

const Icon = styled.img`
    @media (min-width: ${smallScreenBreakpoint1064px}) {

    }
`;

const Button = styled(CTAButton)`
    @media (min-width: ${smallScreenBreakpoint1064px}) {
        margin: 1rem 0 0;
    }
`;

const PersonalContainer = styled.div`
    max-width: 100%;
    height: 100%;
    justify-content: center;
    background-color: white;
    padding: 3rem 1rem;
    width: 990px;
    margin: 0 auto;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        display: flex;
        justify-content: end;
        gap: 2rem;
        position: relative;

        &::before{
            background: linear-gradient(190.23deg, rgba(250, 250, 250, 0.19) 32.14%, rgba(250, 250, 250, 0.527712) 42.92%, rgba(250, 250, 250, 0.757972) 48.36%, #ffffff 58.17%);
            top: 0;
            right: 0;
            bottom: 0;
            left: -420px;
            position: absolute;
            content: '';
            transform: rotate(-89.6deg);
            z-index: 5;
            height: 1000px;
        }
    }
`;

const RightSection = styled(Section)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 6;
    margin-top: 2rem;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        margin-bottom: auto;
        text-align: left;
        max-width: 477px;
        line-height: 1;
        justify-content: start;
        align-items: flex-start;
        margin-top: 1rem;
        background-color: white;
    }
`;

const PersonalHeading = styled.h1`
    font-size: 2rem;
    max-width: 400px;
    text-align: center;
    line-height: 1.3;
    font-weight: bold;
    color: #1A1A1A;
    margin-bottom: 3rem;

    @media (min-width: ${tabletBreakpoint}) {
        font-size: 2.3rem;
        max-width: 460px;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        font-size: 3rem;
        margin-bottom: 1rem;
        text-align: left;
        max-width: unset;
    }
`;

const Gradient = styled.div`
    position: relative;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        height: 440px;
    }

    &::before{
        background: linear-gradient(194.32deg, rgba(250, 250, 250, 0.19) 25.11%, rgba(255, 255, 255, 0.528) 36.03%, rgba(250, 250, 250, 0.757972) 45.82%, #ffffff 60.3%);
        transform: rotate(16.02deg);
        position: absolute;
        content: '';
        top: -132px;
        right: -106px;
        bottom: -272px;
        left: -176px;

        @media (min-width: ${smallScreenBreakpoint1064px}) {
            display: none;
        }
    }
`;

const CenterImage = styled.img`
    background-size: contain;
    display: flex;
    background-position: center;
    object-fit: scale-down;
    width: 100%;
    height: 300px;

    @media (min-width: ${tabletBreakpoint}) {
        height: 350px;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        display: none;
    }
`;

const LeftImage = styled.img`
    background-size: contain;
    height: 390px;
    min-width: 215px;
    background-position: center;
    margin: 0 auto;
    object-fit: scale-down;
    display: none;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        position: absolute;
        display: block;
        left: -8%;
    }
`;

const PersonalWrapper = styled.div`
    width: 100%;
    overflow: hidden;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        position: relative;
    }
`;

const Compatibility = styled.div`
    @media (max-width: ${tabletBreakpoint}) {
        display: none;
    }
`;
