import React, { useContext } from 'react';

import { Trans, withI18n } from '@lingui/react';
import {
    WelcomeWrapper,
    Wrapper,
    Heading,
    Section,
    Description,
    CTAButton,
    ArrowDown,
    ArrowLink,
} from '~/styles/welcome';
import { paths } from '~/../shared/routing';
import { getPath } from '~/../shared/utils';
import { LangContext } from '~/components/../providers/LangProvider';
import TransHTML from '~/components/transHTML';
import { t } from '@lingui/macro';

const HomepageWelcome = () => {
    const ctxLang = useContext(LangContext);

    return (
        <WelcomeWrapper id="welcome" className={'mt-4 mt-lg-0'}>
            <Wrapper>
                <Section>
                    <Heading>
                        <Trans id="global.business.welcome.heading" />
                    </Heading>

                    <Description>
                        <TransHTML id={t`global.business.welcome.description`} />
                    </Description>
                    <CTAButton href={getPath(paths.join_us, ctxLang.state.country)}>
                        <Trans id="global.homepage.for_your_business.cta" />
                    </CTAButton>
                    <ArrowLink href="#reasons">
                        <ArrowDown src="/static/images/new_web/arrow_down.svg" />
                    </ArrowLink>
                </Section>
            </Wrapper>
        </WelcomeWrapper>
    );
};

export default HomepageWelcome;
