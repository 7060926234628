import React, {useContext} from 'react';
import {withI18n} from '@lingui/react';
import {routes} from '~/../shared/routing';
import Router from 'next/dist/client/router';
import {useRouter} from 'next/router';
import {LangContext} from '~/providers/LangProvider';
import {
    supportedCountries,
    countriesNames,
} from '~/providers/LangProvider/countryUtils';
import {Box} from '@material-ui/core';
import {t} from "@lingui/macro";
import {FooterSelect} from "~components/footer/select";

const CountrySwitcher = ({theme = "dark", i18n}) => {
    const router = useRouter();
    const ctx = useContext(LangContext);

    const changeCountry = (toCountry) => {

        // 0) Die if missing these
        if (!ctx || !ctx.actions || !ctx.actions.setCountry) {
            return;
        }

        // special website for peru
        if (toCountry === 'pe') {
            router.push("https://www.trisbee.pe/")
            return;
        }

        // 1) Change country in this instance
        ctx.actions.setCountry(toCountry);

        // 2) Add country to cookies
        document.cookie = 'country=' + toCountry + ';path=/';

        // 3) Decide where to redirect
        let countryName = toCountry.toUpperCase();
        let arrStr = Router.router.route.split(/[//]/);

        let newRoute = `/${countryName}`;

        // if they are any params, add them as well
        let params = arrStr.slice(2).join('/');
        if (params) {
            newRoute = `${newRoute}/${params}`;
        }

        // find template
        const matchingTemplate = routes.find(
            route => route.template === newRoute
        );

        // redirect him to home page
        if (!matchingTemplate && countryName) {
            Router.replace(
                `/${ctx.state.lang}/${countryName}`,
                `/${ctx.state.lang}/${countryName}`
            );
            return;
        }

        // we gonna redirect user to concrete page
        Router.replace(
            matchingTemplate.aliases[ctx.state.lang],
            `/${ctx.state.lang}${matchingTemplate.template}`
        );
    };

    return (
        <Box sx={{minWidth: 120}}>
            <FooterSelect
                theme={theme}
                value={ctx.state.country}
                onChangeCallback={changeCountry}
                label={t`global.chooseCountry`}
                friendlyNames={countriesNames}
                i18n={i18n}
                items={supportedCountries}
            />
        </Box>
    );

};

export default withI18n()(CountrySwitcher);
