import styled from "styled-components";
import {Trans} from "@lingui/react";
import React from "react";
import AlertMessage, {AlertType} from "~components/AlertMessage";

export function Maintenance() {
    return (
        <Wrapper>
            <Message>
                <AlertMessage type={AlertType.danger}>
                    <a href={'/cs/CZ/blog/odstavka-trisbee-pay-2024'}>
                        <Trans
                            id={"maintenance"}
                            message={"We are working on changes, accepting payments is temporarily suspended."}
                        />
                    </a>
                </AlertMessage>
            </Message>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
`;

const Message = styled.div`
    width: 100%;
    margin: 0 auto;
    text-align: center;

    & > * {
        width: 100%;
        display: block;
        text-align: center !important;
    }
`;
