import React, { useContext } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { withI18n } from '@lingui/react';
import { Section } from '~/styles/welcome';
import { paths } from '~/../shared/routing';
import { getPath } from '~/../shared/utils';
import { LangContext } from '~/components/../providers/LangProvider';
import TransHTML from '~/components/transHTML';
import { t } from '@lingui/macro';
import { YellowButton } from '../../styles/buttons';
import { smallScreenBreakpoint1064px, tabletBreakpoint, mobileBreakpoint } from '~/styles/common';

// todo: get rid of "className" probably related with bootstrap, sass
function PersonalWelcome({ i18n }) {
    const ctxLang = useContext(LangContext);
    return (
        <PersonalWrapper id='welcome' className={'mt-lg-0'}>
            <PersonalContainer>
                <LeftImage alt='Trisbee app' src={i18n._(t`personal.page.image`)} />

                <RightSection>
                    <PersonalHeading>
                        <TransHTML id={t`personal.page.title`} />
                    </PersonalHeading>

                    <PersonalSubHeading>
                        <TransHTML id={t`personal.page.subtitle`} />
                    </PersonalSubHeading>

                    <CenterImage alt='Trisbee app' src={i18n._(t`personal.page.image`)} />

                    <Container>
                        <Icon alt='Apple Pay' src='/static/images/home-page/payments/ap-pay.svg' />
                        <Icon alt='Google Pay' src='/static/images/home-page/payments/gpay.svg' />
                        <Icon alt='Visa' src='/static/images/home-page/payments/visa.svg' />
                        <Icon alt='Mastercard' src='/static/images/home-page/payments/mcard.svg' />
                    </Container>
                </RightSection>
            </PersonalContainer>
        </PersonalWrapper>
    );
}

export default withI18n()(PersonalWelcome);

const Container = styled.div`
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin: 2rem 0;

    @media (min-width: ${tabletBreakpoint}) {
        justify-content: flex-start;
        transform: translateY(30px);
        margin: 0;
    }
`;

const Icon = styled.img`
    width: 48px;

    @media (min-width: ${tabletBreakpoint}) {
        width: 50px;
    }
`;

const PersonalContainer = styled.div`
    max-width: 100%;
    height: 100%;
    justify-content: center;
    background-color: white;
    padding: 3rem 1rem;
    max-width: 990px;
    margin: 0 auto;

    @media (min-width: ${tabletBreakpoint}) {
        display: flex;
        gap: 2rem;
    }
`;

const RightSection = styled(Section)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (min-width: ${tabletBreakpoint}) {
        margin-bottom: auto;
        text-align: left;
        max-width: 477px;
        line-height: 1;
        justify-content: start;
        align-items: flex-start;
        margin-top: 6rem;
    }
`;

const PersonalHeading = styled.h1`
    font-size: 2rem;
    max-width: 400px;
    text-align: center;
    line-height: 1.3;
    font-weight: bold;
    color: #1A1A1A;
    margin-bottom: 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        text-align: left;
        font-size: 2.8rem;
        max-width: 650px;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        font-size: 3rem;
        margin-bottom: 2rem;
    }
`;

const PersonalSubHeading = styled.p`
    max-width: 148px;
    width: 100%;
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 2rem;

    @media (min-width: ${tabletBreakpoint}) {
        max-width: 280px;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 0;
    }
`;

const CenterImage = styled.img`
    background-size: contain;
    display: flex;
    height: 376px;
    min-width: 215px;
    background-position: center;
    margin: 0 auto 1rem auto;
    object-fit: scale-down;
    transform: translateX(-40px);

    @media (min-width: ${tabletBreakpoint}) {
       display: none;
    }
`;

const LeftImage = styled.img`
    background-size: contain;
    display: flex;
    height: 539px;
    min-width: 215px;
    margin-right: 2rem;
    background-position: center;
    margin: 0 auto;
    object-fit: scale-down;
    display: none;

    @media (min-width: ${tabletBreakpoint}) {
       display: block;
    }
`;

const PersonalWrapper = styled.div`
    width: 100%;

    @media (min-width: ${tabletBreakpoint}) {
        position: relative;
    }
`;
