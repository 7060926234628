import { Trans } from '@lingui/react';
import {
    WelcomeWrapper,
    RowWrapper,
    SmallerHeading,
    Section,
    Description,
    CTAButton,
    CTAMobileButton,
    ArrowDown,
    ArrowLink,
    ImageSection,
    Image,
} from '~/styles/welcome';

const PricingWelcome = () => {
    return (
        <WelcomeWrapper id="welcome" className="smaller">
            <RowWrapper>
                <Section>
                    <SmallerHeading>
                        <Trans id="global.menu.link.pricing" />
                    </SmallerHeading>

                    <Description>
                        <Trans id="global.pricing.welcome.description" />
                    </Description>
                    <CTAButton>
                        <Trans id="global.pricing.welcome.cta" />
                    </CTAButton>
                </Section>
                <ImageSection>
                    <Image src="/static/images/new_web/percentage_baloons.svg"></Image>
                </ImageSection>
                <CTAMobileButton>
                    <Trans id="global.pricing.welcome.cta" />
                </CTAMobileButton>
            </RowWrapper>
            <ArrowLink href="#payment-acceptance">
                <ArrowDown src="/static/images/new_web/arrow_down.svg"></ArrowDown>
            </ArrowLink>
        </WelcomeWrapper>
    );
};

export default PricingWelcome;
