import styled, { css } from 'styled-components';
import mediaQueries from '~/styles/mediaQueries';
import { HEADER_HEIGHT } from '~/styles/variables';
import { tabletBreakpoint } from '~/styles/common';

export const Nav = styled.nav`
    ${mediaQueries.bpMenuBurgerStage1(css`
        &#block-menu-content {
            display: none;
        }
    `)}

    &.block-burger-content-menu {
        text-align: center;
        padding: 0;
        overflow-y: auto;
    }
`;

export const Heading2 = styled.h2`
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.25px;
    color: #ffffff;

    &.visually-hidden {
        display: none;
    }

    ${Nav}.block-footer-menu-1 &, ${Nav}.block-footer-menu-2 &, ${Nav}.block-footer-menu-3 & {

        @media (max-width: ${tabletBreakpoint}) {
            text-align: center;
    }
}
`;

export const BurgerMenuOverlay = styled.div`
    display: none;
    visibility: hidden;
    opacity: 0;
    overflow-y: hidden;

    // dummy
    width: 100%;
    height: calc(100% - ${HEADER_HEIGHT}px);
    background: #fff;
    background-clip: content-box;

    &.is-active {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        visibility: visible;
        opacity: 1;

        position: absolute;
        top: 0;
        margin-top: ${HEADER_HEIGHT}px;
        z-index: 10000;
        // for safari z-index issue
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
    }

    // To let the navigation expand, should be done in a better way in some refactor
    div, ul, nav {
        height: 100%;
    }
`;

export const UnorderedList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;

    ${Nav}.block-burger-content-menu &, ${Nav}.block-footer-menu-1 &, ${Nav}.block-footer-menu-2 &, ${Nav}.block-footer-menu-3 & {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    ${Nav}.block-footer-menu-1 &, ${Nav}.block-footer-menu-2 &, ${Nav}.block-footer-menu-3 & {
        align-items: flex-start;

        @media (max-width: ${tabletBreakpoint}) {
            align-items: center;
            margin-bottom:45px;
    }

    }
`;

export const ListItem = styled.li`
    display: inline-block;

    &:last-child{
        div{
            right:0;
        }
    }

    &.is-active {
        & > a {
            /* color: #0cc8a8; */
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
    }

    // todo: this is duplicate with navigationDesktop - ItemName
    /* Main menu items */
    & > a {
        font-family: ${({ theme }) => theme.primaryFont};
        display: block;
        text-decoration: none;
        color: #333333;
        cursor: pointer;
        font-size: 16px;
        font-style: normal;
        padding: 10px 20px;
        line-height: 36px;
        letter-spacing: 0.5px;
        font-weight: 500;

        &:hover {
            text-decoration: none;
            color: #0cc8a8;
        }

    }


    ${Nav}.block-menu-content &, ${Nav}.block-burger-content-menu & {
        &.is-active {
            & > a {
                color: #0cc8a8;
                text-decoration: none;
            }
        }
    }
`;

export const MobileListItem = styled.li`
    display: inline-block;
    width: 100%;

    &.is-active {
        & > a {
            /* color: #0cc8a8; */
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
    }

    /* Main menu items */
    & > a {
        font-family: ${({ theme }) => theme.primaryFont};
        display: block;
        text-decoration: none;
        color: #333333;
        cursor: pointer;
        font-size: 16px;
        font-style: normal;
        padding: 10px 20px;
        line-height: 36px;
        letter-spacing: 0.5px;
        font-weight: 300;
        &:hover {
            text-decoration: none;
            color: #0cc8a8;
        }
    }


    ${Nav}.block-menu-content &, ${Nav}.block-burger-content-menu & {
        &.is-active {
            & > a {
                color: #0cc8a8;
                text-decoration: none;
            }
        }
    }

    &:last-child {
        margin-top: auto;
    }
`;

export const OverlaryDownloadMenu = styled.div`
    text-align: center;
    padding-top: 50px;

    & > p {
        color: #a5a5a5;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1.7px;
    }
`;
