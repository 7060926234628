enum PAGE_TYPES {
    INDEX,
    BLOG,
    BUSINESS,
    CASHBACK,
    PERSONAL,
    HOW_TO_PAY,
    PRICING,
    PAYMENT_LINK,
    OCELLOT
}

export default PAGE_TYPES;
