import React, { useContext } from 'react';
import { default as NextLink } from 'next/link';
import { LangContext } from '../providers/LangProvider';
import { routes } from '../../shared/routing';

const Link = ({ href, redirect, as, ...props }) => {
    const ctxLang = useContext(LangContext);
    let hrefAsWithLang = `/${ctxLang.state.lang}${href}`;

    if (as) {
        hrefAsWithLang = `${as}`;
    }

    if (!href) {
        return (
            <button {...props}>{props.children}</button>
        );
    }

    if (redirect) {
        return (
            <a href={href} {...props}>
                {props.children}
            </a>
        );
    }

    /** feature links */
    if (href?.startsWith('mailto:') || href?.startsWith('tel:')) {
        return (
            <a href={href} {...props}>
                {props.children}
            </a>
        );
    }

    /** external link */
    if (href?.startsWith('http')) {
        return (
            <a
                href={href}
                rel="noreferrer noopener"
                target="_blank"
                {...props}
            >
                {props.children}
            </a>
        );
    }

    /** our custom localized routing */
    if (href?.startsWith('/route')) {
        const route = routes.find(rt => href === rt.id);
        let url;
        if (route) {
            url = route.aliases[ctxLang.state.lang] || route.template;

            if (!url) {
                console.error(`Problem with matching route! lang: ${ctxLang.state.lang}, tpl: ${route.template}`);
                console.debug('Route', route);

                /** fallback to EN that should be always here */
                if (!route.template) {
                    url = route.aliases['en'] || route.template;
                }
            }

            // redirect in routing aliases detection
            if (url.startsWith('http')) {
                return (
                    <a href={url} {...props}>
                        {props.children}
                    </a>
                );
            }

            return (
                <NextLink
                    href={route.template}
                    as={`/${ctxLang.state.lang}${url}`}
                >
                    <a {...props}>{props.children}</a>
                </NextLink>
            );
        }
    }

    return (
        <NextLink href={href} as={hrefAsWithLang}>
            <a {...props}>{props.children}</a>
        </NextLink>
    );
};

export default Link;
