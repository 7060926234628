import React, { useState, useRef } from 'react';
import LanguageBar from '~/components/languageBar/LanguageBar.tsx';
import Header from '~/components/header/_header';
import Footer from '~/components/footer/_footer';
import NavigationMobile from '~/components/navigation/_navigationMobile';
import styled from 'styled-components';
import LayoutWidget from "~components/layout/layout-widget";
import PAGE_TYPES from "~page_types";
import {Maintenance} from "~components/layout/maintenance";

function Layout({ welcome, menuWithoutCTAs = false, widget = false, page, children }) {
    if (widget) {
        return (
            <LayoutWidget>
                {children}
            </LayoutWidget>
        );
    }

    const [mobileMenu, setMobileMenu] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const layoutRef = useRef(null);

    const handleMobileMenu = () => {
        if (!mobileMenu) {
            setScrollY(window.pageYOffset);
            layoutRef.current.style.position = 'fixed';
        } else {
            layoutRef.current.style.position = '';
            window.scrollTo(0, parseInt(scrollY || '0'));
        }
        setMobileMenu(!mobileMenu)
    }

    return (
        <>
            <LayoutContainer ref={layoutRef}>
                <div id={'main-container'}>
                    {/* @TODO: solve title here */}
                    <LanguageBar />
                    <Header
                        siteTitle={'Trisbee'}
                        handleMobileMenu={handleMobileMenu}
                        mobileMenu={mobileMenu}
                        menuWithoutCTAs={menuWithoutCTAs}
                        welcome={welcome}
                        page={page}
                    />
                    {page !== PAGE_TYPES.OCELLOT && page !== PAGE_TYPES.BLOG && (
                        <Maintenance />
                    )}
                    <main role="main">
                        <a id="main-content" />
                        <div className="layout-content">{children}</div>
                    </main>
                    <Footer />
                </div>
            </LayoutContainer>
            <NavigationMobile handleMobileMenu={handleMobileMenu} visible={mobileMenu} />
        </>
    );
}

export default Layout;

const LayoutContainer = styled.div`
    font-family: ${({ theme }) => theme.primaryFont};
    .fade-in-section {
        opacity: 0;
        transform: translateY(20vh);
        visibility: hidden;
        transition: opacity 1200ms ease-out, transform 600ms ease-out,
            visibility 1200ms ease-out;
        will-change: opacity, transform, visibility;
    }
    .fade-in-section.is-visible {
        opacity: 1;
        transform: none;
        visibility: visible;
    }
`;
