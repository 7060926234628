import { withI18n } from '@lingui/react';
import { t } from '@lingui/macro';

const TransHTML = ({ i18n, id }) => {

    return (
        <>
            {/* <div style={{ display: 'none' }}>{t`id`}</div> */}
            <span
                dangerouslySetInnerHTML={{
                    __html: i18n._(id),
                    // __html: t`id`,
                }}
            />
        </>
    );
};

export default withI18n()(TransHTML);
