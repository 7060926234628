import React from "react";
import {MenuItem, TextField} from "@material-ui/core";
import styled from "styled-components";

type ThemeType = 'light' | 'dark';

type PropTypes = {
    theme: ThemeType;
    label: string;
    items: string[];
    value: string;
    friendlyNames: {
        key: string;
    };
    onChangeCallback: (newValue: string) => void;
    i18n: any;
}

export function FooterSelect({theme, label, items, value, friendlyNames, onChangeCallback, i18n}: PropTypes) {
    return (
        <StyledTextField
            mode={theme}
            variant='outlined'
            value={value}
            onChange={(e) => onChangeCallback(e.target.value)}
            select
            label={i18n._(label)}
        >
            {items.map(i => (
                <MenuItem
                    key={i}
                    value={i}
                >
                    {friendlyNames[i]}
                </MenuItem>
            ))}
        </StyledTextField>
    );
}

const StyledTextField = styled(TextField)<{ mode: ThemeType }>`
    width: 100%;
    text-align: left;
    background: ${({ mode }) => (mode === "light" ? "white" : "transparent")};
    border-radius: 8px;

    .MuiSelect-root {
        padding: 10px 58px 10px 10px;
        border-radius: 8px;
    }

    // hide label
    .MuiInputLabel-outlined.MuiInputLabel-shrink, legend > span {
        display: none;
    }

    // typo
    .MuiSelect-outlined.MuiSelect-outlined {
        font-family: ${({ theme }) => theme.primaryFont};
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: ${({ mode }) => (mode === "dark" ? "white" : "#667378")};
        border-radius: 8px;

        &:hover, &:active, &:focus {
            color: white;
            background: black;
        }
    }

    // override icon
    .MuiSelect-icon {
        width: 9px;
        height: 5px;
        top: 15px;
        right: 15px;
        content: "";
        background: url('/static/images/icons/arrow-down.svg') center center no-repeat;
        border: 0;
    }

    // hide old icon
    .MuiSelect-icon > path {
        display: none;
    }

    .MuiFormLabel-root.Mui-focused, .MuiOutlinedInput-notchedOutline {
        border: ${({ mode }) => (mode === "dark" ? "1px solid white" : "1px solid transparent")} !important;
        background: inherit !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border: ${({ mode }) => (mode === "dark" ? "1px solid white" : "1px solid transparent")};
        border-radius: 8px;
    }
`;
