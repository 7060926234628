import React from "react";
import styled from "styled-components";
import {tabletBreakpoint} from "~styles/common";

export const OperationSystems = () => (
    <Container>
        <Icon alt='Windows' src='/static/images/ocellot/icons/windows.svg' />
        <Icon alt='MacOS' src='/static/images/ocellot/icons/macos.svg' />
        <Icon alt='IOS' src='/static/images/ocellot/icons/ios.svg' />
        <Icon alt='Android' src='/static/images/ocellot/icons/android.svg' />
        <Icon alt='Linux' src='/static/images/ocellot/icons/linux.svg' />
    </Container>
);

const Container = styled.div`
    display: flex;
    gap: 2rem;

    @media (min-width: ${tabletBreakpoint}) {
        justify-content: center;
    }

    @media (max-width: 380px) {
        gap: 1rem;
    }

    @media (min-width: ${tabletBreakpoint}) {
        justify-content: flex-start;
        transform: translateY(30px);
        order: 0;
        margin: 0;
    }
`;

const Icon = styled.img`
`;
