import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from "styled-components";
import { t } from "@lingui/macro";
import Link from '~/components/link';
import Navigation from '~/components/navigation/_navigation';
import { menu } from '~/../shared/menu';
import Welcome from '~/components/embed/_welcome';
import { LangContext } from '~/providers/LangProvider';
import { paths } from '~/../shared/routing';
import { getPath } from '~/../shared/utils';
import { ContainerBig } from "../../styles/common";
import { HEADER_HEIGHT } from "../../styles/variables";
import mediaQueries from "../../styles/mediaQueries";
import { smallScreenBreakpoint1064px } from '~/styles/common';
import PageTypes from "~page_types";

function Header({ siteTitle, handleMobileMenu, mobileMenu, menuWithoutCTAs, welcome, page }) {
    const ctx = useContext(LangContext);
    const current_menu = menu[ctx.state.country];

    const [scrolledDown, setScrolledDown] = useState(false);

    /**
     * add or remove border-bottom
     */
    const listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const bigger = winScroll > HEADER_HEIGHT;
        setScrolledDown(bigger);
    };

    /**
     * add or remove border-bottom
     */
    useEffect(() => {
        let timer = setTimeout(() => {
            window.addEventListener('scroll', listenToScroll);
            clearTimeout(timer);
        }, 2000);
    }, []);

    let logoSrc = '/static/images/new_web/logo_green.svg';

    /** show ocellot logo instead */
    if (PageTypes.OCELLOT === page) {
        logoSrc = '/static/images/ocellot/pokladna_logo.svg';
    }

    return (
        <>
            <HeaderEl role="banner" mobileMenu={mobileMenu} scrolledDown={scrolledDown}>
                <HeaderContentWrapper>
                    <Logo id="logo">
                        <Link
                            href={getPath(paths.index, ctx.state.country)}
                            style={{ textDecoration: `none` }}
                            rel={'home'}>
                            <Img
                                src={logoSrc}
                                alt={siteTitle}
                                className={'trisbee-logo'}
                            />
                        </Link>
                    </Logo>
                    <MainMenu id="main-menu">
                        <Navigation
                            menu={current_menu.primary}
                            handleMobileMenu={handleMobileMenu}
                            menuWithoutCTAs={menuWithoutCTAs}
                        />
                        <BurgerMenu id="burger-menu">
                            <button
                                id="burger-menu-btn"
                                onClick={() => handleMobileMenu()}
                                type="button"
                            >
                                {mobileMenu && (
                                    <CloseButton />
                                )}
                                {!mobileMenu && (
                                    <>
                                        <MenuTitle>MENU</MenuTitle>
                                        <BurgerImage
                                            src="/static/images/new_web/icons/burger_menu_icon.svg"
                                            alt={t`global.menu.burger.alt`}
                                        />
                                    </>
                                )}
                            </button>
                        </BurgerMenu>
                    </MainMenu>
                </HeaderContentWrapper>
            </HeaderEl>
            {welcome && (
                <Welcome page={page} />
            )}
        </>
    );
}

export default Header;

export const MainMenu = styled.div`
    height: 65px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    @media (min-width: 1081px) {
        margin-right: 74px;
    }

    ${mediaQueries.bpMenuBurgerStage1(css`
        padding-right: 0;
        width: 80%;
        height: 80px;
    `)}
`;

export const HeaderContentWrapper = styled(ContainerBig)`
    display: flex;
    max-width: 1220px;  // todo: this could be the theme variable!
    justify-content: space-between;
`;

export const BurgerMenu = styled.div`
    display: none;
    visibility: hidden;
    opacity: 0;
    position: relative;
    z-index: 10;

    button {
        border:none;
        background-color: transparent;
        padding-right: 0;
    }

    ${mediaQueries.bpMenuBurgerStage1(css`
        display: block;
        visibility: visible;
        opacity: 1;
        width: 100%;
        text-align: right;

        .hamburger {}
    `)}
`;

export const HeaderEl = styled.header`
    padding: .3rem 1rem;
    position: ${props => props.mobileMenu ? "fixed" : "sticky"};
    width: 100%;
    height: ${HEADER_HEIGHT}px;
    top: 0;
    z-index: 102;
    background-color: rgba(253, 253, 255, 0.86);
    backdrop-filter: blur(6px);
    border-bottom: 1px solid ${props => props.scrolledDown ? "#eaeaea" : "transparent"};

    @media only screen and (min-width: 1080px) {
        padding: 11px 32px;
    }
`;

export const Logo = styled.div`
    display: grid;
    height: 70px;
    align-content: center;

    & > a {

        @media only screen and (max-width: 368px) {
            padding-left: 0;
            margin-right: 5px;
        }
    }

    ${mediaQueries.bpMenuBurgerStage1(css`
        height: 80px;
    `)}
`;

export const Img = styled.img`
    max-width: 100%;
    margin-bottom: 0;
`;

export const BurgerImage = styled.img``;

export const MenuTitle = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.6px;
    color: #000000;
    margin-right: 6px;
    text-transform: uppercase;
`;

export const CloseButton = styled.div`
    position: absolute;
    right: 10px;
    top: 0;
    width: 27px;
    height: 27px;

    &:before, &:after {
        position: absolute;
        right: 10px;
        content: ' ';
        height: 27px;
        width: 2px;
        background-color: #0CC8A8;
      }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }
`;
