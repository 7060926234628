import { MobileListItem } from "./styles";
import React, { useContext, useState, useEffect } from "react";
import { Trans } from "@lingui/react";
import { menuItemClassName } from "./_navigation";
import Link from '~/components/link';
import { getPath } from "../../../shared/utils";
import { LangContext } from "../../providers/LangProvider";
import { navigationItemType } from "../../../shared/menu/nav-item-type";
import styled from "styled-components";
import { useRouter } from "next/router";
import { routes } from "../../../shared/routing";
import CTAButtons from "./CTAButtons.tsx";
import { breakpoints } from "~styles/mediaQueries";

const isActive = (asPath, link, lang) => {
    const strippedPath = asPath.slice(3, asPath.length);
    const route = routes.find(rt => link === rt.id);
    return route?.aliases[lang] === strippedPath;
}

const menuState = { notActive: 'not-active', active: 'is-active' };

function DropDownItems({ index, name, link, target, country, lang, handleMobileMenu }) {
    const router = useRouter();
    const [activeClassName, setActiveClassName] = useState(menuState.notActive);

    useEffect(() => {
        if (isActive(router.asPath, getPath(link, country), lang)) {
            setActiveClassName(menuState.active);
        }
        else {
            setActiveClassName(menuState.notActive);
        }
    }, [router])

    return (
        <MenuLink
            key={index}
            href={getPath(link, country)}
            target={target}
            onClick={() => handleMobileMenu()}
        >
            <NavigationButton
                onClick={() => handleMobileMenu()}
                className={activeClassName}
            >
                {name}
            </NavigationButton>
        </MenuLink>
    )
}

/**
 * @todo: let's make it even more simpler, cut it to more functions
 */
function NavigationMobile({ menu, handleMobileMenu, withoutCTAs = false }) {
    const ctx = useContext(LangContext);

    return menu.items.map((i, index) => {
        return (
            <MobileListItem key={index} className={menuItemClassName(i)}>
                {i.type === navigationItemType.dropdown && (
                    <Item>
                        <ItemName>
                            <Name>
                                {i.name}
                            </Name>
                            <ViewAll href={getPath(i?.link, ctx.state.country)} onClick={handleMobileMenu}>
                                <Trans id={"menu.link.viewAll"}>View all</Trans>
                            </ViewAll>
                        </ItemName>

                        <DropDownMenu>
                            {i.dropDownItems.map((i, index) => (
                                <DropDownItems
                                    key={index}
                                    index={index}
                                    name={i.name}
                                    link={i.link}
                                    target={i.target}
                                    country={ctx.state.country}
                                    lang={ctx.state.lang}
                                    handleMobileMenu={() => handleMobileMenu()}
                                />
                            ))}
                        </DropDownMenu>
                    </Item>
                )}
                {i.type === navigationItemType.reguralLink && (
                    <RegularLink
                        href={getPath(i?.link, ctx.state.country)}
                        target={i.target}
                    >
                        <span>{i.name}</span>
                    </RegularLink>
                )}
                {!withoutCTAs && i.type === navigationItemType.ctaButtons && (
                    <MobilePlaceholder>
                        <CTAContainer>
                            <CTAButtons />
                        </CTAContainer>
                    </MobilePlaceholder>
                )}
                {i.type === navigationItemType.separator && (
                    <Separator />
                )}
            </MobileListItem>
        )
    });
}

export default NavigationMobile;

const Item = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
`;

const Name = styled.div`
    font-family: ${({ theme }) => theme.primaryFont};
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    letter-spacing: 0.6px;
    color: #000000;
`;

const ViewAll = styled(Link)`
    margin-left: 1rem;
    font-family: ${({ theme }) => theme.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1px;
    color: #525252;
    cursor: pointer;

    &.is-active {
        color: #0cc8a8;
        text-decoration: none;
}
`;

const ItemName = styled.div`
    display: flex;
    font-family: ${({ theme }) => theme.primaryFont};
    font-style: normal;
    padding: 10px 20px;
    line-height: 35px;
    align-items: baseline;
    height: auto !important;

    &.is-active {
        color: #0cc8a8;
    }
`;

const DropDownMenu = styled.div`
    width: 100%;
    height: auto !important;
    padding: 0 20px 0 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 1;

    &.drop-down-open {
        display: flex;
        height: auto;
    }

    &.drop-down-closed {
        display: none;
        height: 0;
    }
`;

const MenuLink = styled(Link)`
    padding: 10px 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: black;

    &:hover {
        svg {
            color: #0cc8a8;
        }
    }

    &:focus, &:hover {
        text-decoration: none;
    }
`;

const RegularLink = styled(Link)`
    width: 150px;
    text-align: left ;
`;

const NavigationButton = styled.button`
    padding: 6px 10px;
    background-color: white;
    color: black;
    font-family: ${({ theme }) => theme.primaryFont};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    /* Light/Text/Primary */
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 16px;

    &.is-active {
        color: #FFFFFF;
        background: linear-gradient(0deg, #000000, #000000), #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.26);
    }
`;

const CTAContainer = styled.div`
    padding: 25px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: ${({ theme }) => theme.primaryFont};

    a {
        margin: 5px;
    }

    @media screen and (max-width: ${breakpoints.bpMenuBurgerStage1}px) {
        position: absolute;
        bottom: 0;
        height: 100px !important;
        width: 100%;
        z-index: 1000;
        background: rgba(242, 245, 246, 0.26);
        backdrop-filter: blur(10px);
    }
`;

const MobilePlaceholder = styled.div`
    @media screen and (max-width: ${breakpoints.bpMenuBurgerStage1}px) {
        //To enable the scroll below the last menu item
        min-height: 80px;
    }
`;

const Separator = styled.div`
    height: 0 !important;
    width: 90%;
    margin: 15px auto;
    border: 1px solid #F2F2F2;
`;
