export const calculatorIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.87527 2.5003C1.87529 1.69489 2.5282 1.04199 3.3336 1.04199H16.6669C17.4723 1.04199 18.1252 1.69492 18.1252 2.50033V17.5003C18.1252 18.3058 17.4723 18.9587 16.6669 18.9587H3.33333C2.52791 18.9587 1.87499 18.3057 1.875 17.5003L1.87527 2.5003ZM3.3336 2.29199C3.21855 2.29199 3.12527 2.38527 3.12527 2.50032L3.125 17.5003C3.125 17.6154 3.21828 17.7087 3.33333 17.7087H16.6669C16.7819 17.7087 16.8752 17.6154 16.8752 17.5003V2.50033C16.8752 2.38526 16.7819 2.29199 16.6669 2.29199H3.3336Z" fill="#757575" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.79175 4.16699C4.79175 3.82181 5.07157 3.54199 5.41675 3.54199H14.5834C14.9286 3.54199 15.2084 3.82181 15.2084 4.16699V7.91699C15.2084 8.26217 14.9286 8.54199 14.5834 8.54199H5.41675C5.07157 8.54199 4.79175 8.26217 4.79175 7.91699V4.16699ZM6.04175 4.79199V7.29199H13.9584V4.79199H6.04175Z" fill="#757575" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.55806 11.2251C4.80214 10.981 5.19786 10.981 5.44194 11.2251L8.35861 14.1417C8.60269 14.3858 8.60269 14.7815 8.35861 15.0256C8.11453 15.2697 7.7188 15.2697 7.47473 15.0256L4.55806 12.1089C4.31398 11.8649 4.31398 11.4691 4.55806 11.2251Z" fill="#757575" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.35861 11.2251C8.60269 11.4691 8.60269 11.8649 8.35861 12.1089L5.44194 15.0256C5.19786 15.2697 4.80214 15.2697 4.55806 15.0256C4.31398 14.7815 4.31398 14.3858 4.55806 14.1417L7.47473 11.2251C7.7188 10.981 8.11453 10.981 8.35861 11.2251Z" fill="#757575" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0417 14.583C11.0417 14.2378 11.3216 13.958 11.6667 13.958H15.0001C15.3453 13.958 15.6251 14.2378 15.6251 14.583C15.6251 14.9282 15.3453 15.208 15.0001 15.208H11.6667C11.3216 15.208 11.0417 14.9282 11.0417 14.583Z" fill="#757575" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0417 12.083C11.0417 11.7378 11.3216 11.458 11.6667 11.458H15.0001C15.3453 11.458 15.6251 11.7378 15.6251 12.083C15.6251 12.4282 15.3453 12.708 15.0001 12.708H11.6667C11.3216 12.708 11.0417 12.4282 11.0417 12.083Z" fill="#757575" />
    </svg>
)
