import styled from 'styled-components';
import { FHDBreakpoint1920px,tabletBreakpoint } from '~/styles/common';
import { YellowButton } from '~/styles/buttons';
import { smallScreenBreakpoint1064px } from './common';

const WelcomeWrapper = styled.div`
    background-image: url('/static/images/new_web/bg-main.svg');
    background-repeat: no-repeat;
    background-position: center top;
    padding-bottom: 26px;
    background-size: cover;
    padding-top: 134px;
    padding-left: 20px;
    padding-right: 20px;

    &.smaller {
        background-image: url('/static/images/new_web/bg-other.svg');
    }

    @media (max-width: ${tabletBreakpoint}) {
        padding-top: 50px;
    }

    @media (min-width: ${tabletBreakpoint}) {
        position: relative;
        height: calc(100vh - 88px);
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 787px;
    width: 100%;
    margin: 0 auto;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    max-width: 912px;
    width: 100%;
    margin: 0 auto;


    @media (max-width: ${tabletBreakpoint}) {
        flex-direction: column;
    }
`;

const Heading = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    line-height: 85px;
    text-align: center;
    letter-spacing: -1px;
    color: #0cc8a8;

    @media (max-width: ${tabletBreakpoint}) {
        font-size: 45px;
        line-height: 54px;
        text-align: center;
    }
`;



const SmallerHeading = styled(Heading)`
    font-size: 63px;
    text-align: left;
    margin-bottom: 10px;
    line-height: 70px;

    @media (max-width: ${tabletBreakpoint}) {
        font-size: 46px;
        line-height: 45px;
        text-align: center;
    }
`;

const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${WelcomeWrapper}.smaller & {
        align-items: flex-start;

        @media (max-width: ${tabletBreakpoint}) {
            align-items: center;
        }
    }
`;

const ImageSection = styled.section`
    margin-left: 16px;

    @media (max-width: ${tabletBreakpoint}) {
        margin-left: 0px;
        text-align: center;
    }
`;

const Image = styled.img`
    @media (max-width: ${tabletBreakpoint}) {
        width: 80%;
    }
`;

const Description = styled.div`
    margin-top: 8px;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 45px;
    letter-spacing: 0.6px;
    color: #000000;
    text-align: center;

    ${WelcomeWrapper}.smaller & {
        text-align: left;
    }

    @media (max-width: ${tabletBreakpoint}) {
        font-size: 17px;
        line-height: 30px;
        text-align: center;
    }
`;

const CTAButton = styled(YellowButton)`
    margin: 0 auto;

    ${WelcomeWrapper}.smaller & {
        margin: 0;
        margin-right: auto;

        @media (max-width: ${tabletBreakpoint}) {
            margin: 0 auto;
            display: none;
        }
    }
`;

const CTAMobileButton = styled(YellowButton)`
    ${WelcomeWrapper}.smaller & {
        margin: 0 auto;
        display: none;
        margin-top: 30px;

        @media (max-width: ${tabletBreakpoint}) {
            display: inline-block;
        }
    }
`;

const ArrowDown = styled.img`
    margin-top: 95px;
    @media (max-width: ${tabletBreakpoint}) {
        margin-top: 40px;
    }

    @media (max-height: 900px) {
        display: none;
    }
`;

const ArrowLink = styled.a`
    @media (max-width: ${tabletBreakpoint}) {
        text-align: center;
        display: block;
    }

    @media (min-width: ${tabletBreakpoint}) {
        display: block;
        bottom: 50px;
        z-index: 99;
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
    }
`;

export {
    WelcomeWrapper,
    Wrapper,
    RowWrapper,
    Heading,
    SmallerHeading,
    Section,
    Description,
    CTAButton,
    ArrowDown,
    ArrowLink,
    ImageSection,
    Image,
    CTAMobileButton,
};
