export const commonVariables = {
    colors: {
        primary: '#fdd05d',
        secondary: '#fc6e4f',
        primaryGreen: '#27726e',
        darkGreen: '#0F5958',
        status: {
            error: '#fe4f18',
            ok: '#46b181',
        },
        utils: {
            overlay: 'rgba(34, 34, 34, 0.6)',
        },
    },
    paddings: {
        button: '1.6rem 2.4rem',
        wrapper: '2rem',
    },
    maxWidths: {
        container: '1240px',
        bigContainer: '1920px',
    },
};

export const HEADER_HEIGHT = 90;
