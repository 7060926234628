import React, { useContext } from 'react';
import { Trans, withI18n } from '@lingui/react';
import styled, {css} from 'styled-components';
import Navigation from '~/components/navigation/_navigation';
import { LangContext } from '~/providers/LangProvider';
import { menu } from '~/../shared/menu';
import Switchers from './switchers';
import { tabletBreakpoint, smallScreenBreakpoint1064px } from '~/styles/common';

import { FooterWrapper, FooterContent, FooterCopyright } from './styles';
import { Container } from '~/styles/common';
import getConfig from "next/config";
import mediaQueries from "~styles/mediaQueries";

const { publicRuntimeConfig: { APP_VERSION } } = getConfig();

const Footer = React.memo(props => {
    const ctx = useContext(LangContext);
    const current_menu = menu[ctx.state.country];
    return (
        <FooterWrapper id="footer" role="contentinfo">
            <FooterContent id="footer-content">
                <Container>
                    <Row>
                        <LeftBlock>
                            <a href="https://startupwiseguys.com/" target="_blank">
                                <StartupLogo src="/static/images/new_web/startup_wise_guys.svg" />
                            </a>
                        </LeftBlock>

                        <FooterMenu id="footer-menu">
                            <Navigation menu={current_menu.footer_col_1} />
                            <Navigation menu={current_menu.footer_col_2} />
                            <Navigation menu={current_menu.footer_col_3} />
                        </FooterMenu>

                        <RightBlock>
                            <SwitcherWrapper>
                                <Switchers />
                            </SwitcherWrapper>

                            <FooterCopyright>
                                © {new Date().getFullYear()} {` `} Trisbee
                                s.r.o.
                            </FooterCopyright>
                        </RightBlock>
                    </Row>
                </Container>
                <Version>Version: {APP_VERSION}</Version>
            </FooterContent>
        </FooterWrapper>
    );
});

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    justify-content: space-around;
`;

const FooterMenu = styled.div`
    width: unset;
    margin: 0 50px 0 50px;
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    justify-content: space-around;

    ${mediaQueries.bpMenuBurgerStage1(css`
        display: block;
        flex-direction: unset;
        flex-grow: unset;
        justify-content: unset;
    `)}

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0;
            padding: 0;
        }
    }

    a {
        color: #fff;
        font-size: 15px;
        font-weight: 300;
        letter-spacing: 0.32px;
        line-height: 36px;
        text-decoration: underline;
        padding: 0;
        text-align: left;

        &:hover {
            color: #21c298;
            text-decoration: underline;
        }
    }

    @media(max-width: ${tabletBreakpoint}) {
      width: 100%;
    }

    @media(min-width: ${tabletBreakpoint}) {
      display: flex;
      justify-content: space-around;

      li {
        width:85%;
      }
    }
`;

const SwitcherWrapper = styled.div`
    width: 245px;
    margin: 0 0 30px 0;

    @media(min-width: ${tabletBreakpoint}) {
      margin: auto;
    }
`;

const LeftBlock = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 30px;

    @media (max-width: ${tabletBreakpoint}) {
        width: 100%;
        margin-bottom: 30px;
        justify-content: center;
    }
    @media(min-width: ${tabletBreakpoint}) {
      display: flex;
      flex-direction: column;
      a{
        margin: auto;
      }
    }
`;

const RightBlock = styled.div`
    text-align: right;
    margin:0 30px;

    @media (max-width: ${tabletBreakpoint}) {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media (max-width: ${smallScreenBreakpoint1064px}) {
        margin-top: 10px;
    }
`;

const StartupLogo = styled.img`
    width: 100%;
    height: 100px;
`;

const Version = styled.div`
    font-size: 12px;
    margin-top: 30px;
    width: 100%;
    text-align: center;
    opacity: 0.5;
`;

export default withI18n()(Footer);

