import React, { useContext, useEffect, useState } from "react";
import styled, {css} from "styled-components";
import { Trans } from "@lingui/react";
import { LangContext } from '../../providers/LangProvider';

import LangSwitcher from "../footer/langSwitcher";
import CountrySwitcher from "../footer/countrySwitcher";
import {mobileBreakpoint, smallScreenBreakpoint1064px} from "../../styles/common";
import mediaQueries from "~styles/mediaQueries";

export const languageBarStatusKey = "languageBarClosed";
export enum LanguageBarStatusEnum {
    HIDDEN = "hidden",
}

// ToDo: localization, load countries from the enum, or global function
const countries = {
    cz: 'Czechia',
    it: 'Italia',
    sk: 'Slovakia',
    es: 'Spain',
};

const getLocalStorageStatus = () => {
    if (window) {
        const status = localStorage.getItem(languageBarStatusKey);
        if (status === LanguageBarStatusEnum.HIDDEN) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

function LanguageBar() {
    const [hidden, setHidden] = useState<boolean>(false);
    const ctx = useContext(LangContext);

    useEffect(() => {
        setHidden(getLocalStorageStatus());
    }, [])

    const onClose = () => {
        // ToDo: autoclose on both country and language touched/selected?
        localStorage.setItem(languageBarStatusKey, LanguageBarStatusEnum.HIDDEN);
        setHidden(true);
    }

    if (hidden) {
        return <div />;
    }

    return (
        <Wrapper>
            <Container>
                <Grid>
                    <Message>
                        <Trans id={"language.message.info1"}>
                            You are on
                        </Trans>
                        {' ' + (countries[ctx.state.country] || countries.cz) + ' '}
                        <Trans id={"language.message.info2"}>
                            website. Choose your country or region to see location-specific content.
                        </Trans>
                    </Message>
                    <InputsContainer>
                        <StyledCountrySwitcher theme={"light"} />
                        <StyledLangSwitcher theme={"light"} />
                    </InputsContainer>
                    <CloseBarCross onClick={onClose}>
                        <img src="/static/images/new_web/icons/close_icon.svg" alt={'Close'} />
                    </CloseBarCross>
                </Grid>
            </Container>
        </Wrapper>
    );
}

export default LanguageBar;

const Wrapper = styled.div`
    height: 80px;
    font-family: ${({ theme }) => theme.primaryFont};
    background-color: #F3F3F3;
    text-align: center;

    ${mediaQueries.bpMenuBurgerStage1(css`
        height: auto;
        text-align: left;
        padding: 1.5rem 1rem; // todo: use theme variable, it have to fit with header - navigation
    `)}
`;

const Container = styled.div`
    height: 100%;
    max-width: 1220px; // todo: this could be the theme variable!
    margin: 0 auto;
`;

const Grid = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto auto;
    grid-template-areas: 'message inputs close';

    @media (max-width: ${smallScreenBreakpoint1064px}) {
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        grid-template-areas: 'message close' 'inputs .';
    }

    ${mediaQueries.bpMenuBurgerStage1(css`
        text-align: left;
    `)}
`;

const Message = styled.div`
    grid-area: message;
    color: #6D6D6D;
    font-size: 12px;
    font-weight: 300;
    text-align: left;

    ${mediaQueries.bpMenuBurgerStage1(css`
        margin-bottom: 10px;
    `)}
`;

const InputsContainer = styled.div`
    grid-area: inputs;
    display: flex;
    flex-flow: row;
    width: 100%;
    height: 34px;
    flex-wrap: wrap;
    gap: 5px 25px;

    ${mediaQueries.bpMenuBurgerStage1(css`
        display: flex;
        flex-direction: column;
        gap: 0 10px;

        .MuiInputBase-input {
            width: 100px;
        }
    `)}

    .MuiInputBase-input {
        @media (min-width: ${smallScreenBreakpoint1064px}) {
          width: 120px;
        }
    }
`;


const StyledLangSwitcher = styled(LangSwitcher)`
    width: 250px;
`;

const StyledCountrySwitcher = styled(CountrySwitcher)`
    width: 250px;

    @media (max-width: ${smallScreenBreakpoint1064px}) {
        margin-left: initial;
    }
`;

const CloseBarCross = styled.div`
    grid-area: close;
    width: 33px;
    height: 33px;
    cursor: pointer;
    display: grid;
    align-items: center;
    justify-items: center;
    border-radius: 8px;
    margin-left: 35px;

    &:hover {
        background: black;
        color: white;
    }
`;
