import React from "react";
import styled from 'styled-components';

/**
 * Layout without header, footer etc. Show just the content.
 */
function LayoutWidget({ children }) {
    return (
        <Wrapper>
            <div id={'main-container'}>
                <main role="main">
                    <a id="main-content" />
                    <div className="layout-content">{children}</div>
                </main>
            </div>
        </Wrapper>
    );
}

export default LayoutWidget;

const Wrapper = styled.div`
    font-family: ${({ theme }) => theme.primaryFont};
    padding: 20px 20px;
`;
