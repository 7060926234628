import React from 'react';
import { withI18n } from '@lingui/react';
import LangSwitcher from './langSwitcher';
import CountrySwitcher from './countrySwitcher';
import {
    SwitchersWrapper,
    CountrySwitcherWrapper,
    LanguageSwitcherWrapper,
} from './styles';

const Switchers = () => (
    <SwitchersWrapper>
        <CountrySwitcherWrapper>
            <CountrySwitcher />
        </CountrySwitcherWrapper>

        <LanguageSwitcherWrapper>
            <LangSwitcher />
        </LanguageSwitcherWrapper>
    </SwitchersWrapper>
);

export default withI18n()(Switchers);
