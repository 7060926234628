export const trisbeeIcon = () => (
    <svg width="21" height="12" viewBox="0 0 21 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21 1.10626H10.5207L9.50982 0H7.41187L11.9535 4.87085L7.41187 9.72862H9.50982L13.956 4.86387L11.9692 2.68974H15.5437V11.1175H17.5366V2.68974H21V1.10626Z"
            fill="currentColor"/>
        <path
            d="M5.32487 0H3.21916L7.02401 4.12943H0V5.75373H7.03982L3.21916 9.88227H5.32487L9.88227 4.94822L5.32487 0Z"
            fill="currentColor"/>
    </svg>
)


