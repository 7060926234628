import React, {useContext} from 'react';
import getConfig from "next/config";
import {routes} from '~/../shared/routing';
import Router from 'next/dist/client/router';
import {LangContext} from '~/providers/LangProvider';
import {
    languageNames,
    languageNamesShort,
    languageList
} from '~/providers/LangProvider/langUtils';
import {Box} from '@material-ui/core';
import {withI18n} from "@lingui/react";
import {t} from "@lingui/macro";
import {FooterSelect} from "~components/footer/select";

const {
    publicRuntimeConfig: {
        featureFlag: {
            localePreview,
        }
    }
} = getConfig();


const LangSwitcher = ({shortLanguageName = false, theme = "dark", i18n}) => {
    const ctxLang = useContext(LangContext);

    const getQueriesSection = () => {
        return Object.keys(Router.router.query).reduce((acc, queryKey, i) => {
            if (queryKey === 'lang') {
                return acc;
            }

            if (i === 0) {
                return `${acc}?${queryKey}=${Router.router.query[queryKey]}`;
            }

            return `${acc}&${queryKey}=${Router.router.query[queryKey]}`;
        }, '');
    };

    const changeLanguage = (toLang) => {
        // 0) Die if missing these
        if (!ctxLang || !ctxLang.actions || !ctxLang.actions.setLang) {
            return;
        }

        // 1) Change lang in this instance
        ctxLang.actions.setLang(toLang);

        // 2) Set new cookies
        if ('es' !== toLang) {
            // @todo: hot fix
            document.cookie = 'lang=' + toLang + ';path=/';
        }

        // 3) Shallow change of url
        // 3.1) Is it a template?
        const matchingTemplate = routes.find(
            route => route.template === Router.router.route
        );

        if (matchingTemplate) {
            const queriesSection = getQueriesSection();

            const newRouteName =
                matchingTemplate.aliases && matchingTemplate.aliases[toLang]
                    ? matchingTemplate.aliases[toLang]
                    : matchingTemplate.template;

            window.history.replaceState(
                '',
                '',
                `/${toLang}${newRouteName}${queriesSection}`
            );

            return;
        }

        //If blog article was not found do anything (page [article].js manages that)
        if (Router.router.route.includes('blog')) {
            return;
        }

        // 3.2) If nothing matches
        Router.replace(`/${toLang}`, '/');
    };

    // filter-out things in localePreview
    const getAllowedLanguages = languageList(localePreview);

    return (
        <Box sx={{minWidth: 120}}>
            <FooterSelect
                theme={theme}
                value={ctxLang.state.lang}
                items={getAllowedLanguages}
                friendlyNames={shortLanguageName ? languageNamesShort : languageNames}
                label={t`chooseLanguage`}
                onChangeCallback={changeLanguage}
                i18n={i18n}
            />
        </Box>
    );
};

export default withI18n()(LangSwitcher);

