import React, { useContext } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import {Trans} from "@lingui/react";
import {getPath} from "../../../shared/utils";
import {BlackTransparentButton, YellowButton} from "../../styles/buttons";
import { paths } from "../../../shared/routing";
import { LangContext } from "../../providers/LangProvider";

export default function CTAButtons() {
    const router = useRouter();
    const ctx = useContext(LangContext);

    // todo: this is probably not enough, see: http://localhost:3011/cs/CZ/cenik what about read it from props / context?
    const isBusiness = true; // router.pathname.includes("business")

    const loginHref = isBusiness
                        ? getPath(paths.externalBusinessWebApp, ctx.state.country)
                        : getPath(paths.externalPayApp, ctx.state.country);

    const signUpHref = isBusiness
                        ? getPath(paths.join_us, ctx.state.country)
                        : getPath(paths.externalPayApp, ctx.state.country);

    return (
        <>
            <CustomBlackTransparentButton
                href={loginHref}
            >
                <Trans id={"global.login"}>Log in</Trans>
            </CustomBlackTransparentButton>
            <CustomYellowButton
                href={signUpHref}
            >
                <Trans id={"global.signUp"}>Sign up</Trans>
            </CustomYellowButton>
        </>
    );
}

const CustomBlackTransparentButton = styled(BlackTransparentButton)`
    min-width: 0;
    padding: 8px 17px;
    text-transform: inherit;
    font-weight: 600;
    border-width: 1px;
`;

const CustomYellowButton = styled(YellowButton)`
    min-width: 0;
    padding: 8px 17px;
    text-transform: inherit;
    font-weight: 600;
    border: 1px solid #fdcb54;
    border-bottom-color: #f3b00f;

    &:hover {
        border-color: #f3b00f;
        text-shadow: 0 1px 0 #fdcb54;
    }
`;
