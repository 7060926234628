import React from 'react';
import { withI18n } from '@lingui/react';
import PageTypes from '~/page_types.ts';
import HomepageWelcome from '~/components/homepage/homepageWelcome';
import PersonalWelcome from '~/components/personal/personalWelcome';
import PricingWelcome from '~/components/pricing/pricingWelcome';
import PaymentLinkWelcome from "~/components/payment-link/welcome";
import {OcellotWelcome} from "~/components/ocellot/header-welcome";

const Welcome = ({ page, i18n }) => {
    return (
        <>
            {page === PageTypes.BUSINESS && <HomepageWelcome />}
            {page === PageTypes.PERSONAL && <PersonalWelcome />}
            {page === PageTypes.OCELLOT && <OcellotWelcome i18n={i18n} />}
            {page === PageTypes.PAYMENT_LINK && <PaymentLinkWelcome />}
            {page === PageTypes.PRICING && <PricingWelcome />}
        </>
    );
};

export default withI18n()(Welcome);
