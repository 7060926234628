import styled from 'styled-components';
import { tabletBreakpoint } from "../../styles/common";

const FooterWrapper = styled.div`
    font-family: ${({ theme }) => theme.primaryFont};
    width: 100%;
`;

const FooterContent = styled.div`
    width: 100%;
    padding: 65px 20px;
    background: #414c51;
    color: #fff;
`;

const FooterCopyright = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.25px;
    color: #ffffff;
    margin-top: 22px;
`;

const Socials = styled.div`
    margin-bottom: 40px;
`;

const UnorderedList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const ListItem = styled.li`
    display: inline-block;
    margin-right: 10px;
    padding: 0;
`;

const SocialLink = styled.a`
    display: block;
    padding-top: 1px;
    border: 1px solid #fff;
    border-radius: 50px;
    width: 31px;
    overflow: hidden;
    height: 31px;
    text-align: center;
    vertical-align: middle;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background: #ffc107;
        border-color: #ffc107;
    }
`;

const Image = styled.img`
    vertical-align: middle;
`;

const SwitchersWrapper = styled.div`

  @media (max-width: ${tabletBreakpoint}) {
    h2 {
      text-align: center;
    }
  }

`;

const LanguageSwitcherWrapper = styled.div`
    width: 245px;
    margin-top: 16px;
    background-color: #414c51;
`;

const CountrySwitcherWrapper = styled.div`
    width: 245px;
    background-color: #414c51;
    margin: 15px 0 0 0;
`;

export {
    FooterWrapper,
    FooterContent,
    FooterCopyright,
    SwitchersWrapper,
    Socials,
    UnorderedList,
    ListItem,
    SocialLink,
    Image,
    LanguageSwitcherWrapper,
    CountrySwitcherWrapper,
};
