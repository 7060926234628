import styled from "styled-components";
import {ReactElement} from "react";

export enum AlertType {
    info = 'info',
        danger = 'danger',
        warning = 'warning'
}

const Theme = {
    info: {
        background: '#033c7c',
        color: '#FFFFFF',
        borderColor: '#b9d3f1'
    },
    danger: {
        background: '#fcdde1',
        color: '#903340',
        borderColor: '#fbcfd5',
    },
    warning: {
        background: '#fdf0db',
        color: '#946e2d',
        borderColor: '#fcebcc',
    }
}

type ThemeType = {
    background: string;
    color: string;
    borderColor: string;
};

const getTheme = (type: AlertType): ThemeType => {
    const name = type.toString();
    if(!(name in Theme)) {
        throw Error(`Type ${name} is not in defined themes!`);
    }

    return Theme[name as keyof typeof Theme];
};


const AlertMessage = ({type, children}: {
    type: AlertType, children: string | ReactElement
}) => (
    <StyledMessage type={type}>
        {children}
    </StyledMessage>
);

const StyledMessage = styled.div<{ type: AlertType }>`
  margin-bottom: 0;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px 1px 1px 10px;
  border-image: none 100% / 1 / 0 stretch;
  border-radius: 0.25rem;
  overflow-wrap: break-word;
  word-break: break-word;
  font-family: inherit;
  border-color: ${({type}) => getTheme(type).borderColor};
  background: ${({type}) => getTheme(type).background} none repeat scroll 0% 0%;
  color: ${({type}) => getTheme(type).color};
  display: flex;

    & > a {
        color: ${({type}) => getTheme(type).color};
    }
`;


export default AlertMessage;
