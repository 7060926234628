import React from 'react';
import { menu } from '~/../shared/menu';
import { useRouter } from 'next/router';
import { Heading2, Nav, UnorderedList } from './styles';
import NavigationDesktop from "./navigationDesktop";
import NavigationMobile from "./navigationMobile";
import NavigationFooter from "./navigationFooter";
import { NavigationType } from "~components/navigation/navigation-type";

function Navigation({ menu, handleMobileMenu, menuWithoutCTAs }) {
    if (undefined === menu) {
        console.error("Problem - menu is undefined!");
        return <div />;
    }

    const getNavigation = (type) => {
        switch (type) {
            case NavigationType.BLOCK_MENU_CONTENT:
                return (
                    <NavigationDesktop
                        menu={menu}
                        withoutCTAs={menuWithoutCTAs}
                    />
                );
            case NavigationType.BLOCK_MENU_BURGER_CONTENT:
                return (
                    <NavigationMobile
                        handleMobileMenu={handleMobileMenu}
                        menu={menu}
                        withoutCTAs={menuWithoutCTAs}
                    />
                );
            default:
                return (
                    <NavigationFooter menu={menu} />
                );
        }
    }

    return (
        <Nav role="navigation" className={menu.id} id={menu.id}>
            <Heading2 className={'visually-hidden'}>
                {menu.name}
            </Heading2>
            <div>
                <UnorderedList>
                    {getNavigation(menu.id, handleMobileMenu, menuWithoutCTAs)}
                </UnorderedList>
            </div>
        </Nav>
    );
}

// todo: probably duplicate somewhere
export const menuItemClassName = i => {
    return matchActive(i.link)
        ? 'is-active'
        : 'not-active' + ' ' + (i.dropdown ? 'item-dropdown' : '');
};

// todo: write test
const matchActive = link => {
    const router = useRouter();

    let routeMatches = false;
    const currentRoute = router.route;

    if (currentRoute.length <= 3) {
        return Boolean(false);
    }

    if (typeof link === 'string') {
        routeMatches = link.includes(currentRoute.substring(1));
        return routeMatches;
    } else {
        if (link === undefined) {
            return;
        }
        Object.keys(link).forEach(function (key) {
            if (link[key].includes(currentRoute.substring(1))) {
                routeMatches = true;
                return;
            }
        });
    }

    return Boolean(routeMatches);
};

Navigation.defaultProps = {
    menu: menu.primary,
};

export default Navigation;

