import Navigation from './_navigation';
import React, { useEffect, useContext } from 'react';
import { withI18n } from '@lingui/react';
import { LangContext } from '../../providers/LangProvider';
import {menu} from '~/../shared/menu';
import { BurgerMenuOverlay } from './styles';
import { Container } from '~/styles/common';
import styled from 'styled-components';

const NavigationMobile = ({visible, handleMobileMenu}) => {
    const ctx = useContext(LangContext);
    const current_menu = menu[ctx.state.country];

    useEffect(() => {
        const mainContainer = document.getElementById('main-container');
        if (visible) {
            mainContainer.classList.add('menu-is-active');
        } else {
            mainContainer.classList.remove('menu-is-active');
        }
    });

    return (
        <BurgerMenuOverlay
            id="burger-menu-overlay"
            className={visible ? 'is-active' : ''}
        >
            <NavigationContainer>
                <Navigation handleMobileMenu={handleMobileMenu} menu={current_menu.mobile} />
            </NavigationContainer>
        </BurgerMenuOverlay>
    );
};

export default withI18n()(NavigationMobile);

const NavigationContainer = styled(Container)``
