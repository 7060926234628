import {ListItem} from "./styles";
import React, {useContext} from "react";
import {menuItemClassName} from "./_navigation";
import Link from '~/components/link';
import {getPath} from "../../../shared/utils";
import {LangContext} from "../../providers/LangProvider";


const NavigationFooter = ({menu}) => {
    const ctx = useContext(LangContext);

    return (
<>
    {menu.items.map((i, index) => (
        <ListItem key={index} className={menuItemClassName(i)}>
            <Link
                href={getPath(i?.link, ctx.state.country)}
                target={i.target}
            >
                <span>{i.name}</span>
            </Link>
        </ListItem>
    ))}
</>
)

}
export default NavigationFooter
