import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import getConfig from 'next/config';
import FavIcons from './favicons';
import { withI18n } from '@lingui/react';
import { t } from '@lingui/macro';
import getFavicons from '~/components/favicons';

const defaultValuesPersonal = {
    title: t`seo.personal.title_default`,
    description: t`seo.personal.description_default`,
    ogImage: t`seo.personal.og_image_default`,
    ogSiteName: t`seo.personal.og_site_name`,
};

const defaultValuesBusiness = {
    title: t`seo.business.title_default`,
    description: t`seo.business.description_default`,
    ogImage: t`seo.business.og_image_default`,
    ogSiteName: t`seo.business.og_site_name`,
};

const { publicRuntimeConfig } = getConfig();

function ogImageS3(input) {
    return !input.startsWith('http')
        ? publicRuntimeConfig.CDN_URL + input
        : input;
}

function SEO({
    description,
    title,
    titleSuffix,
    ogImage,
    ogType,
    noIndex,
    business,
    i18n,
}) {
    const _props = {
        description: description,
        title: title,
        titleSuffix: titleSuffix,
        ogImage: ogImage,
        noIndex: noIndex,
        personal: business,
        ogType: ogType,
    };

    const defaultValues = _props.personal
        ? defaultValuesPersonal
        : defaultValuesBusiness;

    const finalProps = Object.keys(_props).reduce((acc, propKey) => {
        acc[propKey] = _props[propKey] || defaultValues[propKey];
        return acc;
    }, {});

    const googleTagManager = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${publicRuntimeConfig.GTM}');`;

    return (
        <Head>
            <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
            <meta name="HandheldFriendly" content="true" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="author" content="Trisbee s.r.o." />
            <title>
                {i18n._(finalProps.title)}
                {finalProps.titleSuffix ? ' — Trisbee' : ''}
            </title>
            {finalProps.noIndex && (
                <meta name={'robots'} content={'noindex, nofollow'} />
            )}
            <meta
                name={'description'}
                content={i18n._(finalProps.description)}
            />
            <meta
                property={'og:image'}
                content={ogImageS3(i18n._(finalProps.ogImage))}
            />
            <meta
                property={'og:description'}
                content={i18n._(finalProps.description)}
            />
            <meta property={'og:title'} content={i18n._(finalProps.title)} />
            <meta property={'og:type'} content={finalProps.ogType} />
            <meta property={'og:site_name'} content={finalProps.ogSiteName} />
            <meta
                property="og:email"
                content={i18n._(t`seo.shared.og_email`)}
            />

            <meta
                property="fb:app_id"
                content={publicRuntimeConfig.FACEBOOK_APP_ID}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name={'twitter:title'} content={i18n._(finalProps.title)} />
            <meta
                name={'twitter:image'}
                content={ogImageS3(i18n._(finalProps.ogImage))}
            />
            <meta
                name={'twitter:description'}
                content={i18n._(finalProps.description)}
            />
            <meta name={'twitter:creator'} content={'Trisbee s.r.o.'} />
            {getFavicons()}
            <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: googleTagManager,
                }}
            />
        </Head>
    );
}

SEO.propTypes = {
    business: PropTypes.bool,
    // title: PropTypes.object.isRequired,
    titleSuffix: PropTypes.bool,
    // description: PropTypes.object,
    // ogImage: PropTypes.object,
    ogType: PropTypes.string,
    noIndex: PropTypes.bool,
    i18n: PropTypes.object.isRequired,
};

SEO.defaultProps = {
    personal: false,
    noIndex: false,
    titleSuffix: true,
    ogType: 'website',
};

export default withI18n()(SEO);
