export const locationIcon = () => (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15 8C15 13.4444 8 18.1111 8 18.1111C8 18.1111 1 13.4444 1 8C1 6.14349 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14349 15 8Z"
            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M8.00008 10.3337C9.28875 10.3337 10.3334 9.28899 10.3334 8.00033C10.3334 6.71166 9.28875 5.66699 8.00008 5.66699C6.71142 5.66699 5.66675 6.71166 5.66675 8.00033C5.66675 9.28899 6.71142 10.3337 8.00008 10.3337Z"
            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

