import React from "react";
import {Trans} from "@lingui/react";
import {paths} from "../routing";
import {navigationItemType} from "./nav-item-type";
import {benefitIcon} from "../../static/images/new_web/icons/menu_icons/benefitIcon";
import {qrIcon} from "../../static/images/new_web/icons/menu_icons/qr_code_icon_menu";
import {locationIcon} from "../../static/images/new_web/icons/menu_icons/location_icon_menu";
import {paymentLink} from "../../static/images/new_web/icons/menu_icons/payment_link_icon_menu";
import {starIcon} from "../../static/images/new_web/icons/menu_icons/star_icon_menu";
import {faqIcon} from "../../static/images/new_web/icons/menu_icons/faq_icon_menu";
import {trisbeeIcon} from "../../static/images/new_web/icons/menu_icons/trisbee_icon_menu";

export const menuItemsEurope = [
    {
        type: navigationItemType.separator,
    },
    {
        name: <Trans id="global.menu.link.for.entrepreneurs" />,
        link: paths.index,
        dropdownName: 'business',
        type: navigationItemType.dropdown,
        dropDownItems: [
            {
                name: <Trans id="global.menu.link.benefits.trisbee" />,
                link: paths.business_benefits,
                icon: benefitIcon(),
            },
            {
                name: <Trans id="global.menu.link.how" />,
                link: paths.business_how_it_work,
                icon: qrIcon(),
            },
            {
                name: <Trans id="global.menu.link.pricing" />,
                link: paths.pricing,
                icon: locationIcon(),
            },
            {
                name: (
                    <Trans
                        id="global.menu.link.payment_link"
                        message="Payment link"
                    />
                ),
                link: paths.business_payment_link,
                icon: paymentLink(),
            },
            {
                name: <Trans id="global.menu.link.reference" />,
                link: paths.business_reference,
                icon: starIcon(),
            },
            {
                name: <Trans id="global.menu.link.faq" />,
                link: paths.faq,
                icon: faqIcon(),
            },
        ],
    },
    {
        type: navigationItemType.separator,
    },
    {
        name: <Trans id="global.menu.link.personal" />,
        link: paths.personal,
        type: navigationItemType.dropdown,
        dropdownName: 'personal',
        dropDownItems: [
            {
                name: <Trans id="global.menu.link.benefits" />,
                link: paths.personal_reason_to_pay,
                icon: trisbeeIcon(),
            },
            {
                name: <Trans id="global.menu.link.how" />,
                link: paths.personal_how_to_pay,
                icon: qrIcon(),
            },
            {
                name: <Trans id="global.menu.link.reference" />,
                link: paths.personal_reference,
                icon: starIcon(),
            },
            {
                name: <Trans id="global.menu.link.faq" />,
                link: paths.faq_customer,
                icon: faqIcon(),
            },
        ],
    },
    {
        type: navigationItemType.separator,
    },
    {
        name: <Trans id="global.menu.company.new" />,
        link: paths.about,
        type: navigationItemType.dropdown,
        dropdownName: 'company',
        dropDownItems: [
            {
                name: <Trans id="global.menu.link.about" />,
                link: paths.about,
            },
            {
                name: <Trans id="global.menu.link.blog" />,
                link: paths.blog,
            },
            {
                name: <Trans id="global.menu.link.contact" />,
                link: paths.contact,
            },
            {
                name: <Trans id="global.menu.link.terms_and_conditions" />,
                link: paths.terms,
            },
        ],
    },
    {
        type: navigationItemType.separator,
    },
    {
        type: navigationItemType.ctaButtons,
    },
];
