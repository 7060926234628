import { navigationItemType } from "../../../shared/menu/nav-item-type";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled, {css, keyframes} from "styled-components";
import { ListItem } from "./styles";
import { menuItemClassName } from "./_navigation";
import Link from '~/components/link';
import { LangContext } from "../../providers/LangProvider";
import { getPath } from "../../../shared/utils";
import CTAButtons from "./CTAButtons.tsx";

function verifyItems(menu) {
    if (false === menu.hasOwnProperty('items')) {
        console.error('Missing items for requested menu');
        return <div className={'alert alert-warning'}>no menu items found</div>;
    }
}

function DropDownItem({ item, country }) {
    return (
        <MenuLink
            href={getPath(item.link, country)}
            target={item.target}
        >
            <span>{item.icon}</span>
            <NameSpan>{item.name}</NameSpan>
        </MenuLink>
    );
}

function NavigationDesktop({ menu, withoutCTAs = false }) {
    const ctx = useContext(LangContext);
    const [openDropDown, setOpenDropDown] = useState(undefined);
    const dropDownMenu = useRef([]);

    verifyItems(menu);

    const closeOnClickOutside = (e) => {
        const checkIfClickOutside = dropDownMenu.current.every(el => !el.contains(e.target));
        if (checkIfClickOutside) {
            setOpenDropDown(undefined);
        }
    }

    useEffect(() => {
        window.addEventListener("click", closeOnClickOutside);
        return () => {
            window.removeEventListener("click", closeOnClickOutside)
        }
    }, [])

    const isActive = (i) => {
        return openDropDown === i.dropdownName;
    }

    const itemClassName = (i) => {
        return isActive(i) ? "is-active" : "not-active";
    }

    const dropDownClassName = (i) => {
        return isActive(i) ? "drop-down-open" : "drop-down-closed";
    }

    const handleOnClickItem = (i) => {
        setOpenDropDown(isActive(i) ? undefined : i.dropdownName);
    }

    return (
        <>
            {menu.items.map((item, index) => (
                <ListItem  key={index} className={menuItemClassName(item)}>
                    {item.type === navigationItemType.dropdown && (
                        <Item
                            key={item.link}
                            onClick={() => handleOnClickItem(item)}
                            ref={(e) => dropDownMenu.current[index] = e}
                        >
                            <ItemName className={itemClassName(item)}>
                                <Name className={menuItemClassName(item)}>
                                    {item.name}
                                </Name>
                                <Arrow className={itemClassName(item)}>
                                    &gt;
                                </Arrow>
                            </ItemName>
                            <DropDownMenu className={dropDownClassName(item)}>
                                {item.dropDownItems.map((item2, index) => (
                                    <DropDownItem
                                        key={index}
                                        item={item2}
                                        country={ctx.state.country}
                                    />
                                ))}
                            </DropDownMenu>
                        </Item>
                    )}
                    {item.type === navigationItemType.reguralLink && (
                        <RegularLink
                            href={getPath(item?.link, ctx.state.country)}
                            target={item.target}
                        >
                            <Name>{item.name}</Name>
                        </RegularLink>
                    )}
                    {!withoutCTAs && item.type === navigationItemType.ctaButtons &&
                        <CTAContainer>
                            <CTAButtons />
                        </CTAContainer>
                    }
                </ListItem>
            ))}
        </>
    );
}

export default NavigationDesktop;

const ItemDesign = css`
    line-height: 36px;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: 500;
    color: #333333;
    font-family: ${({theme}) => theme.primaryFont};

    &.is-active {
        color: #0cc8a8;
    }

    &:hover {
        color: #0cc8a8;
    }
`;


const Item = styled.div`
    ${ItemDesign}
`;

const Name = styled.div`
    &.is-active {
        color: #0cc8a8;
        text-decoration: none;
    }
`;

const RegularLink = styled(Link)`
`;

const ItemName = styled.div`
    display: flex;
    cursor: pointer;
    padding: 22px 20px;
`;

const Arrow = styled.div`
    margin-left: 10px;
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;

    &.is-active {
     transform: rotate(270deg);
    }
`;

// todo: replace this by easy-in effect
const open = keyframes`
  0% {
      max-height: 0;
  }

  100% {
      max-height: 500px;
  }
`;

const DropDownMenu = styled.div`
    min-width: 250px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 44px rgba(39, 88, 107, 0.14);
    background-color: white;
    flex-direction: column;
    z-index: 1;
    position: absolute;
    overflow: hidden;

    &.drop-down-open {
        display: flex;
        height: auto;

        // speed of opening the menu
        animation: ${open} 0.3s ease;

        a > span {
            font-size: 15px;
        }
    }

    &.drop-down-closed {
        display: none;
        height: 0;
    }
`;

const NameSpan = styled.span`
    padding-left: 15px;
    ${ItemDesign}
`;

const MenuLink = styled(Link)`
    padding: 5px 10px;
    text-decoration: none;
    display: flex;
    align-items: center;

    font-size: 12px;
    color: #333333;

    border-top: 1px solid #f5f5f5;
    border-radius: 5px;


    &:first-child {
        border-top: 0;
    }

    svg {
        width: 15px;
        height: auto;
    }

    span {
        font-weight: 400;
    }

    &:hover {
        background: #f7fffc;

        a, span {
            color: inherit;
            text-shadow: 0 1px 0 #FFFFFF;
        }
    }

    &:focus, &:hover {
        text-decoration: none;
    }
`;

const CTAContainer = styled.div`
    margin-left: 25px;
    display: flex;
    align-items: center;
    font-family: ${({ theme }) => theme.primaryFont};

    a {
        margin: 0 5px;
    }
`;
