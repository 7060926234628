import { map } from 'ramda';
import { css } from 'styled-components';

const breakpoints = {
    phone: 500,
    tablet: 880,
    wide: 1300,
    bpMenuBurgerStage1: 1080,
    bpMenuBurgerStage2: 700,
};

const emify = px => `${px / 16}em`;

const mediaQueries = map(
    value => rules => css`
        @media (max-width: ${emify(value)}) {
            ${rules};
        }
    `,
    breakpoints
);

const heightBreakpoints = {
    mid: 900,
    midSm: 800,
};

const mediaQueriesHeight = map(
    value => rules => css`
        @media (max-height: ${emify(value)}) {
            ${rules};
        }
    `,
    heightBreakpoints
);

export { breakpoints, emify, heightBreakpoints, mediaQueriesHeight };

export default mediaQueries;
