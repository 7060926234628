import {czechiaMenu} from "./menu/country/cz";
import {slovakiaMenu} from "./menu/country/sk";
import {spainMenu} from "./menu/country/es";
import {italiaMenu} from "./menu/country/it";

export const menu = {
    cz: czechiaMenu,
    sk: slovakiaMenu,
    es: spainMenu,
    it: italiaMenu,
};
