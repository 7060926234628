import styled, {css} from 'styled-components';
import Link from '~/components/link';

export const BasicButtonLink = css`
    min-width: 164px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 16px 32px;
    display: inline-block;
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    border: none;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: initial;
    }
`;

const BasicButton = styled(Link)`
  ${BasicButtonLink};
`;

const YellowButton = styled(BasicButton)`
    background: #fdcb54;
    color: #000000;

    &:hover {
        background-color: #f3b00f;
    }
`;

const WhiteButton = styled(BasicButton)`
    background-color: white;
    color: black;
    border: 1px solid black;

    &:hover {
        background-color: black;
        color: white;
    }
`;

const GreenButton = styled(BasicButton)`
    background-color: green;
    color: black;
`;

const BlackTransparentButton = styled(YellowButton)`
    border: 2px solid #000000;
    color: #000000;
    background: transparent;

    &:hover {
        background-color: #000000;
        color: white;
    }
`;

export { YellowButton, GreenButton, WhiteButton, BlackTransparentButton };
