import {buildMenu} from "../build-menu";
import {NavigationType} from "~components/navigation/navigation-type";
import {mainMenuItemsCzechia} from "../main-czechia";
import {globalFooters} from "../global-footer";

export const czechiaMenu = {
    primary: buildMenu(
        NavigationType.BLOCK_MENU_CONTENT,
        'Primary menu',
        mainMenuItemsCzechia
    ),
    mobile: buildMenu(
        NavigationType.BLOCK_MENU_BURGER_CONTENT,
        'Mobile menu',
        mainMenuItemsCzechia
    ),
    ...globalFooters,
}
